import { Injectable } from '@angular/core';
import { BehaviorSubject, skip } from 'rxjs';
import { Industries } from '../shared/util/industries';
import { getKeyByValue } from '../shared/util/getKeyByValue';

@Injectable({
  providedIn: 'root',
})
export class MapIndustryService {
  public readonly activeSelection: BehaviorSubject<Industries> =
    new BehaviorSubject<Industries>(Industries.CONVENIENCE_STORES);

  public activeIndustryKey: string = getKeyByValue(
    Industries,
    this.activeSelection.getValue()
  );

  public wasIndustrySelected: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor() {
    this.activeSelection.pipe(skip(1)).subscribe((industry) => {
      this.changeActiveIndustry(industry);
      this.wasIndustrySelected.next(true);

      this.activeIndustryKey = getKeyByValue(Industries, industry);
    });
  }

  private changeActiveIndustry(activeSelection: any): void {
    switch (activeSelection) {
      //TODO: find out what will industries affect except of WAWA for Target audience
      case Industries.CONVENIENCE_STORES:
        // this.layerStore.activeLayer.next(PEOPLE_PER_MI2);
        break;

      case Industries.RETAIL:
        // this.layerStore.activeLayer.next(MEDIAN_INCOME);
        break;

      case Industries.DRUGSTORES:
        // this.layerStore.activeLayer.next(PEOPLE_PER_MI2);
        break;

      default:
        console.error(
          'Implement Switch case for' + this.activeSelection.getValue()
        );
    }
  }
}
