import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { BreakpointObserverService } from '../../../shared/services/breakpoint-observer.service';
import { SubscriptionActivatedComponent } from './subscription-activated/subscription-activated.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionPlans } from '../user.model';
import { AuthenticationService } from '../../authentication.service';
import {
  PromoCodeLookup,
  SubscriptionsService,
} from '../../subscriptions.service';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';

export const TRIGGER_REFRESH_USER_DATA = 'TRIGGER_REFRESH_USER_DATA';

@Component({
  selector: 'app-subscriptions-menu',
  templateUrl: './subscriptions-menu.component.html',
  styleUrls: ['./subscriptions-menu.component.scss',
    '../../../app.component.scss']
})
export class SubscriptionsMenuComponent implements OnInit, AfterViewInit {
  @ViewChildren('planElement', {read: ElementRef}) planElements!: QueryList<ElementRef>;

  // Filter out plans that are not meant to be user-available
  public readonly plans = Object.values(SubscriptionPlans.description).filter(el => !el.notAvailableForUser)

  public promoCodeInputSubject = new BehaviorSubject<string | undefined>(undefined);

  public activePlanIndex: number = 1
  public urlSelectedPlanIndex: number | undefined
  public isPeriodMonthly: boolean = true

  public promoCodeValid: boolean = false;
  public promoCodeDetails: PromoCodeLookup | null = null;
  public promoCode: string | null = null

  public userSubscription: SubscriptionPlans | null = null;

  constructor(public breakpointObserverService: BreakpointObserverService,
              private dialog: MatDialog,
              private authService: AuthenticationService,
              private subscriptionsService: SubscriptionsService,
              private activatedRoute: ActivatedRoute,) {
    authService.userPersonalData.subscribe(user => {
      if (user !== null) {
        this.userSubscription = user.subscription?.plan ?? null;
      }
    });

  }

  ngOnInit(): void {
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === TRIGGER_REFRESH_USER_DATA) {
        setTimeout(() => {
          this.authService.refreshUserInfo()
        }, 1500)
      }
    })

    const paymentSuccess = this.activatedRoute.snapshot.queryParamMap.get('payment-success');

    if (paymentSuccess === 'true') {
      this.dialog.open(SubscriptionActivatedComponent, {
        maxWidth: '624px',
        maxHeight: '364px',
      });
    }

    this.setupPromoCodeValidation();
  }

  ngAfterViewInit() {
    this.parsePlanFromQuery()
    this.parsePromoCodeFromQuery()
  }

  public handlePromoCodeInput(event: Event): void {
    const input = (event.target as HTMLInputElement).value.trim();
    this.promoCode = input
    this.promoCodeInputSubject.next(input);

  }

  private validatePromoCode(promoCode: string): void {
    this.subscriptionsService.checkPromoCode(promoCode.toUpperCase()).subscribe({
      next: (response) => {
        if (response.object) {
          this.promoCodeValid = response.object.active;
          this.promoCodeDetails = response.object;
        } else {
          this.promoCodeValid = false;
          this.promoCodeDetails = null;
        }
      },
      error: () => {
        this.promoCodeValid = false;
        this.promoCodeDetails = null;
      },
    });
  }

  private setupPromoCodeValidation(): void {
    this.promoCodeInputSubject
      .pipe(
        debounceTime(500),
        distinctUntilChanged() // Ignore repeated inputs
      )
      .subscribe((promoCode) => {
        if (promoCode) {
          this.validatePromoCode(promoCode);
        } else {
          this.promoCodeValid = false;
          this.promoCodeDetails = null;
        }
      });
  }

  public parsePlanFromQuery(): void {
    const plan = this.activatedRoute.snapshot.queryParamMap.get('plan');
    const yearlyParam = this.activatedRoute.snapshot.queryParamMap.get('yearly');

    this.isPeriodMonthly = yearlyParam === null || yearlyParam === 'false';

    this.urlSelectedPlanIndex = this.plans.findIndex(el => el.requestKey === plan);

    if (this.urlSelectedPlanIndex >= 0 && this.planElements.length > this.urlSelectedPlanIndex) {
      this.planElements.toArray()[this.urlSelectedPlanIndex].nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public parsePromoCodeFromQuery(): void {
    const promoCode = this.activatedRoute.snapshot.queryParamMap.get('promo')

    if (promoCode) {
      this.promoCodeInputSubject.next(promoCode)
      this.promoCode = promoCode
    }
  }

  public clearPromoCode(): void {
    this.promoCode = null
    this.promoCodeInputSubject.next(undefined)
  }

  public onToggleOuterButton(index: number) {
    this.activePlanIndex = index;
  }
}
