import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  SubscriptionPlanDescription,
  SubscriptionPlans,
} from '../../user.model';
import { ModalService } from '../../../../shared/services/modal.service';
import { PromoCodeLookup } from '../../../subscriptions.service';

@Component({
  selector: 'app-plan-with-description',
  templateUrl: './plan-with-description.component.html',
  styleUrls: [
    './plan-with-description.component.scss',
    '../../../../app.component.scss',
  ],
})
export class PlanWithDescriptionComponent implements OnInit, OnChanges {
  @Output() toggleOuterButton: EventEmitter<void> = new EventEmitter();

  @Input() planInfo!: SubscriptionPlanDescription;
  @Input() isThisSubscriptionActive: boolean = false;
  @Input() isPeriodMonthly!: boolean;
  @Input() isSubscriptionUrlSelected!: boolean;
  @Input() promoCodeDetails: PromoCodeLookup | null = null;
  @Input() promoCode: string | null = null;

  public freeDaysFromPromoCode: number | null = null;
  public freePeriodString: string | null = null;

  public isSeeIndicatorsActive: boolean = false;

  constructor(private modalService: ModalService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.promoCodeDetails) {
      this.handlePromoCodeDetails();
    }
  }

  ngOnInit(): void {}

  public handleSubscriptionClick(plan: SubscriptionPlans): void {
    this.modalService.handleSubscriptionConfirmationModal(
      plan,
      this.isPeriodMonthly,
      this.freePeriodString,
      this.promoCode
    );
  }

  private handlePromoCodeDetails(): void {
    if (!this.promoCodeDetails?.active) {
      this.resetPromoCodeDetails();
      return;
    }

    if (this.isPromoCodeApplicable()) {
      this.updateFreePeriodDetails();
    }
  }

  private resetPromoCodeDetails(): void {
    this.freeDaysFromPromoCode = null;
    this.freePeriodString = null;
  }

  private isPromoCodeApplicable(): boolean {
    return !!this.promoCodeDetails?.toSubscriptions.includes(
      this.planInfo.requestKey as SubscriptionPlans
    );
  }

  private updateFreePeriodDetails(): void {
    this.freeDaysFromPromoCode = this.promoCodeDetails!.numberOfFreeDays;

    const months = Math.floor(this.freeDaysFromPromoCode / 30);
    const years = Math.floor(months / 12);

    if (years >= 1) {
      this.freePeriodString = years === 1 ? '1 year' : `${years} years`;
    } else if (months >= 1) {
      this.freePeriodString = months === 1 ? '1 month' : `${months} months`;
    } else {
      this.freePeriodString = `${this.freeDaysFromPromoCode} days`;
    }
  }
}
