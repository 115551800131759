import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { JsonResponse } from '../api/backend-config';
import { SelectedCellService } from '../../map/mapbox/services/selected-cell.service';
import { getKeyByValue } from '../util/getKeyByValue';

export enum AiDescriptionSections {
  GENERAL_OVERVIEW = 'General overview',
  HOUSING = 'Housing',
  HEALTH = 'Health',
  WEALTH = 'Wealth',
  WISDOM = 'Wisdom',
  NEIGHBORS = 'Neighbors',
  EDUCATION = 'Education',
  ENVIRONMENT = 'Environment',
  CRIME = 'Crime',
}

export type AiDescriptionResponse = {
  systemPrompt: string // for debug
  prompt: string // for debug
  text: string
}

@Injectable({
  providedIn: 'root',
})
export class AiService {
  public featureToAiResponseMap: Map<string, Partial<Record<AiDescriptionSections, string>>> = new Map();

  constructor(private http: HttpClient,
              private selectedCellService: SelectedCellService) {}

  public getCellDescription(
    geoId: string,
    section: string
  ): Observable<JsonResponse<AiDescriptionResponse>> {
    const sectionKey = getKeyByValue(
      AiDescriptionSections,
      section
    );

    return this.http.get<JsonResponse<AiDescriptionResponse>>(
      `${environment.apiUrl}ai/description?cellGeoId=${geoId}&cellType=${this.selectedCellService.getCellType()}&dataSection=${sectionKey}`
    );
  }
}
