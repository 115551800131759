import { Pipe, PipeTransform } from '@angular/core';
import { AiDescriptionSections } from '../../services/ai.service';
import {
  COUNTY_LEVEL_LAYER,
  H3_RES5_LEVEL_LAYER,
  H3_RES7_LEVEL_LAYER,
  STATE_LEVEL_LAYER,
} from '../../../map/mapbox/services/layer-store.service';

const SectionCellTypeMap: { [key in AiDescriptionSections]: string[] } = {
  [AiDescriptionSections.GENERAL_OVERVIEW]: [
    COUNTY_LEVEL_LAYER,
    STATE_LEVEL_LAYER,
  ],
  [AiDescriptionSections.HEALTH]: [
    COUNTY_LEVEL_LAYER,
    STATE_LEVEL_LAYER,
    H3_RES5_LEVEL_LAYER,
    H3_RES7_LEVEL_LAYER,
  ],
  [AiDescriptionSections.WEALTH]: [COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER],
  [AiDescriptionSections.WISDOM]: [COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER],
  [AiDescriptionSections.HOUSING]: [H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER],
  [AiDescriptionSections.EDUCATION]: [H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER],
  [AiDescriptionSections.ENVIRONMENT]: [
    H3_RES5_LEVEL_LAYER,
    H3_RES7_LEVEL_LAYER,
  ],
  [AiDescriptionSections.CRIME]: [H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER],
  [AiDescriptionSections.NEIGHBORS]: [],
};

@Pipe({
  name: 'isSectionAvailable',
})
export class IsSectionAvailablePipe implements PipeTransform {
  transform(section: keyof typeof SectionCellTypeMap, level: string): boolean {
    const allowedCellTypes = SectionCellTypeMap[section] || [];
    return allowedCellTypes.includes(level);
  }
}
