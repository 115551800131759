import { Pipe, PipeTransform } from '@angular/core';
import { FeatureFilter } from '../filters-menu.service';
import {
  LIFE_EXPECTANCY,
  STRUCTURE_BUILT_MEDIAN_YEAR,
} from '../../../../../../shared/types/feature-data-type';

export const STEP = 'STEP';
export const MAX_LIMIT = 'MAX_LIMIT'
export const MIN_LIMIT = 'MIN_LIMIT'

@Pipe({
  name: 'sliderValues',
})
export class SliderValues implements PipeTransform {
  transform(
    filter: FeatureFilter,
    type: typeof STEP | typeof MAX_LIMIT | typeof MIN_LIMIT
  ): number {
    switch (type) {
      case STEP:
        if (
          filter.featureConst === STRUCTURE_BUILT_MEDIAN_YEAR ||
          filter.featureConst === LIFE_EXPECTANCY
        ) {
          return 1;
        } else {
          return filter.limits.max! !== 0
            ? Math.abs(filter.limits.max!) * 0.05
            : Math.abs(filter.limits.min!) * 0.05;
        }

      case MIN_LIMIT:
        if (filter.featureConst === STRUCTURE_BUILT_MEDIAN_YEAR || filter.featureConst === LIFE_EXPECTANCY) {
          return filter.limits.min! - 1
        } else {
          return filter.limits.min! - Math.abs(filter.min!) * 0.1 ?? 0;
        }

      case MAX_LIMIT:
        if (filter.featureConst === STRUCTURE_BUILT_MEDIAN_YEAR || filter.featureConst === LIFE_EXPECTANCY) {

          return filter.limits.max! + 1
        } else {
          return filter.limits.max! + Math.abs(filter.max!) * 0.1 ?? 0
        }

      default: throw Error('Illegal state: unexpected type ${type}')
    }
  }
}
