import { Pipe, PipeTransform } from '@angular/core';
import { ColorscaleService } from './colorscale.service';
import { LayerStoreService } from '../../services/layer-store.service';
import { FeatureFocusService } from '../../../feature-focus/feature-focus.service';

@Pipe({
  name: 'isAnimatedColorscale',
})
export class IsAnimatedColorscalePipe implements PipeTransform {
  constructor(
    private colorScaleService: ColorscaleService,
    private featureFocusService: FeatureFocusService,
    private layerStore: LayerStoreService
  ) {}

  transform(isBeingLoaded?: boolean): unknown {
    return (
      this.colorScaleService.colorScaleForFeature !==
        this.layerStore.activeLayer.value || isBeingLoaded
    );
  }
}
