import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'preformattedText',
})
export class PreformattedTextPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Replace **text** with <strong>text</strong>
    value = value.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Replace *text* with <em>text</em>
    value = value.replace(/\*(.*?)\*/g, '<em>$1</em>');

    return value;
  }
}
