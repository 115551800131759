<div class="modal" *ngIf="isMapRoute">
  <button class="close-button" (click)="close()">
    <img src="assets/icons/Cancel.svg" alt="close window" />
  </button>

  <h2 *ngIf="!isRequestPending; else titleSkeleton">
    {{ data.title }}
  </h2>
  <ng-template #titleSkeleton>
    <div class="skeleton skeleton-title"></div>
  </ng-template>

  <h4 *ngIf="!isRequestPending; else sectionSkeleton">
    {{ selectedSection }}
  </h4>
  <ng-template #sectionSkeleton>
    <div class="skeleton skeleton-section"></div>
  </ng-template>

  <div *ngIf="!isRequestPending; else textSkeleton" class="text-container">
    <div *ngIf="isRequestError; else formattedText">
      {{ requestErrorText }}
    </div>
    <ng-template #formattedText>
      <div [innerHTML]="text! | preformattedText"></div>
    </ng-template>
  </div>

  <ng-template #textSkeleton>
    <div class="skeleton skeleton-text"></div>
    <div class="skeleton skeleton-text"></div>
  </ng-template>

  <div *ngIf="!isRequestPending; else reportLinkSkeleton" class="report-link">
    <span class="link-like-text" (click)="handleReportRedirect()">
      Order your Community Compass report
    </span>
    for detailed insights and personalized recommendations.
  </div>

  <ng-template #reportLinkSkeleton>
    <div class="skeleton skeleton-text"></div>
  </ng-template>

  <div *ngIf="!isRequestPending; else buttonSkeleton" class="sections-selector">
    <ng-container *ngFor="let button of Object.values(AiDescriptionSections)">
      <button
        class="section-button"
        *ngIf="button | isSectionAvailable: (layerStore.activeLevel | async)!"
        [ngClass]="selectedSection === button ? 'active' : ''"
        (click)="handleSectionChange(button)">
        {{ button }}
      </button>
    </ng-container>
  </div>

  <ng-template #buttonSkeleton>
    <div class="sections-selector">
      <ng-container *ngFor="let button of Object.values(AiDescriptionSections)">
        <div
          class="skeleton skeleton-button"
          *ngIf="button | isSectionAvailable: (layerStore.activeLevel | async)!">
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
