import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { JsonResponse } from '../shared/api/backend-config';
import { ARBITRARY_CELL } from '../map/mapbox/services/layer-store.service';
import { ReportTypes } from '../user/user/user.model';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import {
  SELECTED_POLYGON,
  SelectedCellService,
} from '../map/mapbox/services/selected-cell.service';
import { MapBoxService } from '../map/mapbox/mapbox.service';

export interface RequestPricesResponse {
  paymentUrl: string;
  totalPriceCents: number;
  reports: {
    reportType: ReportTypes;
    quantity: number;
    priceCents: number;
  }[];
}

export interface RequestPricesBody {
  reports: {
    location: {
      cellId: string | number,
      cellType: string | undefined
      name: string,
    },
    type: string
  }[]
}

export interface RequestedReport {
  url?: string,
  name: string,
  orderedAt: string,
  type: string,
  status: string
}

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  public lastCreatedCellId: string | null = null
  public lastSelectedReportType: ReportTypes | null = null
  public pricesData: RequestPricesResponse | null = null

  public selectedLocation: string | null = null
  public selectedRadius: number | null = null
  public selectedFeature: MapboxGeoJSONFeature | null = null

  public isRedirectedToSelectLocation = false



  constructor(private http: HttpClient,
              private selectedCellService: SelectedCellService,
              private mapboxService: MapBoxService) {
    this.selectedCellService.selectedCellGeoId.subscribe(id => {
      this.setSelectedReportsFeature(this.selectedCellService.getSelectedFeature())
    })
  }

  public getRequestedReports(): Observable<JsonResponse<RequestedReport[]>> {
    return this.http.get<JsonResponse<RequestedReport[]>>(`${environment.apiUrl}report/my`)
  }

  public requestPrices(type: 'arbitrary' | 'selected'): Observable<JsonResponse<RequestPricesResponse>> {
    const body = this.getRequestPricesBody(type)
    return this.http.post<JsonResponse<RequestPricesResponse>>(`${environment.apiUrl}report/order`, body)
  }

  public getRequestPricesBody(type: 'arbitrary' | 'selected'): RequestPricesBody {
    const isArbitrary = type === 'arbitrary'

    return {
      reports: [
        {
          location: {
            cellId: isArbitrary ? this.lastCreatedCellId! : this.selectedFeature!.id!,
            cellType: isArbitrary ? ARBITRARY_CELL : this.selectedCellService.getCellType(),
            name: isArbitrary ? this.selectedLocation! + ', '+ this.selectedRadius + ' mile'
              : this.selectedLocation!,
          },
          type: this.lastSelectedReportType!
        }
      ]
    }
  }

  public handleReportsDataOnRedirect(): void {
    // Set to true to handle redirect properly and skip type selection in order-report
    this.isRedirectedToSelectLocation = true;

    // On this step we will determine what report type is selected based on what description we have
    this.setSelectedReportType(ReportTypes.COMMUNITY_COMPASS);

    this.setSelectedReportsFeature(this.selectedCellService.getSelectedFeature())
  }

  public setSelectedReportsFeature(feature: MapboxGeoJSONFeature | null): void {
    this.selectedFeature = feature
  }

  public setSelectedReportType(reportType: ReportTypes): void {
    this.lastSelectedReportType = reportType
  }

  public deselectReportsFeature(): void {
    this.selectedFeature = null

    if (this.mapboxService.reportsMap.getLayer(SELECTED_POLYGON)) {
      this.mapboxService.reportsMap.removeLayer(SELECTED_POLYGON)
    }
  }

  public outlineReportCell(feature: MapboxGeoJSONFeature): void {
    this.mapboxService.reportsMap.addLayer({
      id: SELECTED_POLYGON,
      type: 'line',
      source: {
        type: 'geojson',
        data: feature,
      },
      paint: {
        'line-color': '#695dff',
        'line-width': 2,
      },
    });
  }

  public resetData(): void {
    this.lastCreatedCellId = null
    this.pricesData = null
  }
}
