import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Industries } from '../../util/industries';
import { LevelLayers } from '../../../map/mapbox/visualization/level-selection/levels';
import {
  H3_RES5_LEVEL_LAYER,
  H3_RES7_LEVEL_LAYER,
  LayerStoreService,
} from '../../../map/mapbox/services/layer-store.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { ActiveToolService, MapTools } from '../../../map/active-tool.service';
import { LEVEL_SELECTION_DROPDOWN } from '../../../map/mapbox/visualization/level-selection/level-selection.component';
import { SubscriptionPlans } from '../../../user/user/user.model';
import { UserAccessService } from '../../../user/access/user-access.service';
import { ModalService } from '../../services/modal.service';
import { limitedCellsResolutionText } from '../../../user/map-redirect-modal/map-redirect-modal-text';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  @Output() readonly activeSelection = new EventEmitter<
    Industries | LevelLayers
  >();

  @Input() features!: Industries | LevelLayers;
  @Input() isDropdownActive!: boolean;
  @Input() dropdownType!: string;

  public currentValue!: string;

  public levelLayerSize: string = '';

  constructor(
    private layerStore: LayerStoreService,
    private activeToolService: ActiveToolService,
    private accessService: UserAccessService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.dropdownType === 'level') {
      this.handleLevelSubscription();
    }

    this.activeToolService.activeTool
      .pipe(takeUntil(this.destroy$))
      .subscribe((activeTool) => {
        this.isDropdownActive =
          this.dropdownType === 'level' &&
          activeTool === MapTools.LEVEL_SELECTION_TOOL;
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public handleItemClick(data: any): void {
    // If level is inaccessble simply show modal and return, don't change level itself
    if (this.dropdownType === LEVEL_SELECTION_DROPDOWN) {
      const filteredLevels = Object.entries(LevelLayers)
        .filter(([key, value]) =>
          this.accessService.getIsUnauthorized()
            ? SubscriptionPlans.unauthorizedUser.accessibleLevels.includes(key)
            : this.accessService.getUserPlan()
            ? SubscriptionPlans.description[
                this.accessService.getUserPlan() as SubscriptionPlans
              ].accessibleLevels.includes(key)
            : SubscriptionPlans.trialDescription.accessibleLevels.includes(key)
        )
        .map(([key, value]) => ({ key, value }));

      if (!filteredLevels.some((level) => level.value === data)) {
        this.modalService.openModal(limitedCellsResolutionText);
        return;
      }
    }

    this.toggleDropdown();
    this.activeSelection.emit(data);
  }

  private handleLevelSubscription(): void {
    this.layerStore.activeLevel
      .pipe(takeUntil(this.destroy$), debounceTime(0))
      .subscribe((layer) => {
        if (layer === H3_RES5_LEVEL_LAYER || layer === H3_RES7_LEVEL_LAYER) {
          this.currentValue = (LevelLayers as any)[layer];

          if (layer === H3_RES7_LEVEL_LAYER) {
            this.levelLayerSize = '(1 mile)';
          } else if (layer === H3_RES5_LEVEL_LAYER) {
            this.levelLayerSize = '(5 mile)';
          }
        } else {
          this.currentValue = (LevelLayers as any)[layer];
          this.levelLayerSize = '';
        }
      });
  }

  public toggleDropdown(): void {
    this.isDropdownActive = !this.isDropdownActive;
  }

  //prevent enum | keyvalue from sorting into an alphabetical order
  public sortFeatures(): number {
    return 0;
  }

  protected readonly H3_RES7_LEVEL_LAYER = H3_RES7_LEVEL_LAYER;
  protected readonly H3_RES5_LEVEL_LAYER = H3_RES5_LEVEL_LAYER;
}
