import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNumber } from '@turf/helpers';
import { FeatureFilter, FiltersMenuService } from '../filters-menu.service';
import { MAX_LIMIT, MIN_LIMIT, STEP } from './slider-values';
import {
  BACHELORS_DEGREE_OR_HIGHER_FRACTION,
  featuresToMultiplyBy100,
  targetAudienceIndustries,
} from '../../../../../../shared/types/feature-data-type';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() filter!: FeatureFilter;
  @Input() activeLevel!: string;
  @Output() removeFilter = new EventEmitter();

  public indexesAndRatiosFeatures = [
    ...featuresToMultiplyBy100,
    ...targetAudienceIndustries,
    BACHELORS_DEGREE_OR_HIGHER_FRACTION,
  ];

  public isMinInputFocused = false;
  public isMaxInputFocused = false;

  constructor(public filtersService: FiltersMenuService) {}

  ngOnInit() {}

  public updateFilter(
    filter: FeatureFilter,
    value: number,
    isMin: boolean
  ): void {
    isMin ? (filter.min = value) : (filter.max = value);

    this.filtersService.updateFilters();
  }

  public emitRemoveFilter(): void {
    this.removeFilter.emit();
  }

  public handleInputFocus(type: 'min' | 'max'): void {
    // Set focus flag based on type
    if (type === 'min') {
      this.isMinInputFocused = true;
    } else {
      this.isMaxInputFocused = true;
    }
  }

  public handleInputBlur(
    type: 'min' | 'max',
    event: Event,
    feature: string
  ): void {
    const input = event.target as HTMLInputElement;
    let parsedValue = parseFloat(input.value);

    if (!isNaN(parsedValue)) {
      // Apply feature-specific logic (e.g., convert percentages to ratios)
      if (this.indexesAndRatiosFeatures.includes(feature)) {
        parsedValue /= 100;
      }

      // Store the precise value internally
      if (type === 'min') {
        this.filter.min = parsedValue;
        this.isMinInputFocused = false;
      } else {
        this.filter.max = parsedValue;
        this.isMaxInputFocused = false;
      }
    } else {
      // Revert to the last valid internal value if the input is invalid
      input.value = String(
        this.indexesAndRatiosFeatures.includes(feature)
          ? (type === 'min' ? this.filter.min : this.filter.max)! * 100
          : type === 'min'
          ? this.filter.min
          : this.filter.max
      );
    }
  }

  public handleInputChange(
    type: 'min' | 'max',
    event: Event,
    feature: string
  ): void {
    const input = event.target as HTMLInputElement;
    let parsedValue = parseFloat(input.value);

    if (!isNaN(parsedValue)) {
      if (this.indexesAndRatiosFeatures.includes(feature)) {
        // Process as a percentage
        parsedValue /= 100; // Convert percentage back to ratio
      }

      // Update the internal filter
      if (type === 'min') {
        this.filter.min = parsedValue;
      } else {
        this.filter.max = parsedValue;
      }

      this.filtersService.updateFilters();
    }
  }

  public handleInput(event: Event): void {
    const input = event.target as HTMLInputElement;

    // Allow only numbers, one optional dot, and an optional leading minus sign
    const sanitizedValue = input.value.replace(/[^0-9.-]/g, '');

    // Ensure there's at most one dot and it comes after the minus sign (if present)
    const parts = sanitizedValue.split('.');
    let newValue =
      parts.length > 2
        ? `${parts[0]}.${parts.slice(1).join('')}`
        : sanitizedValue;

    // Ensure the minus sign appears only at the beginning
    if (newValue.includes('-')) {
      newValue = newValue.replace(/-/g, ''); // Remove all instances of '-'
      newValue = `-${newValue}`; // Re-add '-' to the start
    }

    input.value = newValue;
  }

  protected readonly isNumber = isNumber;
  protected readonly Math = Math;
  protected readonly STEP = STEP;
  protected readonly MIN_LIMIT = MIN_LIMIT;
  protected readonly MAX_LIMIT = MAX_LIMIT;
}
