import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AiDescriptionSections, AiService } from '../../services/ai.service';
import { catchError, debounceTime, Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ReportsService } from '../../../reports/reports.service';
import { LayerStoreService } from '../../../map/mapbox/services/layer-store.service';

@Component({
  selector: 'app-ai-description-modal',
  templateUrl: './ai-description-modal.component.html',
  styleUrls: [
    './ai-description-modal.component.scss',
    '../../../app.component.scss',
  ],
})
export class AiDescriptionModalComponent implements OnInit {
  private isToastShown = false;

  public isMapRoute = true;
  public isRequestPending = false;
  public isRequestError = false;

  public requestErrorText =
    'This section is not available yet. Please explore other sections for more information';

  public selectedSection = AiDescriptionSections.GENERAL_OVERVIEW;
  public text: string | null = null;

  constructor(
    private aiService: AiService,
    private dialogRef: MatDialogRef<AiDescriptionModalComponent>,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private reportsService: ReportsService,
    public router: Router,
    public layerStore: LayerStoreService,
    @Inject(MAT_DIALOG_DATA)
    public data: { geoId: string; level: string; title: string }
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const path = this.router.url.split('?')[0].split('#')[0];
        this.isMapRoute = path === '/';
        this.cdr.detectChanges();
      });

    if (this.layerStore.isCurrentLevelCells()) {
      this.selectedSection = AiDescriptionSections.HOUSING;
    }

    if (!this.isSectionTextExist(this.selectedSection)) {
      this.requestAreaDescription();
    } else if (this.isSectionTextExist(this.selectedSection)) {
      this.text = this.aiService.featureToAiResponseMap.get(this.data.geoId)![
        this.selectedSection
      ]!;

      this.isRequestError = false;
    }
  }

  public handleSectionChange(selection: AiDescriptionSections): void {
    this.selectedSection = selection;

    if (this.isSectionTextExist(this.selectedSection)) {
      this.text = this.aiService.featureToAiResponseMap.get(this.data.geoId)![
        this.selectedSection
      ]!;

      this.isRequestError = false;
    } else {
      this.requestAreaDescription();
    }
  }

  public handleReportRedirect(): void {
    this.reportsService.handleReportsDataOnRedirect();

    this.router.navigate(['/reports/order'], {
      queryParamsHandling: 'merge',
    });
  }

  private requestAreaDescription(): void {
    this.isRequestPending = true;
    this.aiService
      .getCellDescription(this.data.geoId, this.selectedSection)
      .pipe(
        debounceTime(250),
        catchError((err) => {
          if (!this.isToastShown) {
            this.toastr
              .warning(this.requestErrorText)
              .onHidden.subscribe(() => (this.isToastShown = false));

            this.isToastShown = true;
          }
          this.isRequestPending = false;
          this.isRequestError = true;
          return of(null);
        })
      )
      .subscribe((data) => {
        if (!data || !data.object.text) {
          return;
        }

        const text = data.object.text;

        console.log(
          'system prompt: ',
          data.object.systemPrompt,
          'prompt: ',
          data.object.prompt
        );

        // Merge the new field into the existing object for this geoId
        const existingData =
          this.aiService.featureToAiResponseMap.get(this.data.geoId) || {};
        const updatedData = {
          ...existingData,
          [this.selectedSection]: text,
        };

        this.aiService.featureToAiResponseMap.set(this.data.geoId, updatedData);

        this.text = text;
        this.isRequestPending = false;
        this.isRequestError = false;
      });
  }

  public close(): void {
    this.dialogRef.close();
  }

  private isSectionTextExist(section: AiDescriptionSections): boolean {
    return !!(
      this.aiService.featureToAiResponseMap.get(this.data.geoId) &&
      this.aiService.featureToAiResponseMap.get(this.data.geoId)![section]
    );
  }

  protected readonly AiDescriptionSections = AiDescriptionSections;
  protected readonly Observable = Observable;
  protected readonly Object = Object;
}
