import { Injectable } from '@angular/core';
import { NaturalId } from '../map/menu/right-menu/layers-menu/population-menu/population.service';
import { SubscriptionPlans } from './user/user.model';
import { catchError, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { JsonResponse } from '../shared/api/backend-config';
import { environment } from '../../environments/environment';

export interface AccessibleFeaturesIds {
  h3r7Cells: NaturalId[];
  h3r5Cells: NaturalId[];
}

export enum AccessibleFeatureState {
  LIMITED,
  ALL,
}

// If user payment card is linked and additional actions in stripe are not required for payment, then paymentUrl is null
export type SubscriptionPaymentUrl = {
  paymentUrl: string | null
}

export interface PromoCodeLookup {
  // true if can be applied
  // false if not found or expired or already used
  active: boolean;
  // list of subscriptions for which such promo code can be applied
  toSubscriptions: SubscriptionPlans[];
  // for how many days subscription will be free. Divide to 30 and round to get months
  numberOfFreeDays: number;
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {
  private readonly apiURL = environment.apiUrl
  private readonly userSubscriptionURL = this.apiURL + 'user/subscription/'

  // public accessibleFeaturesList: NaturalId[] = [];
  public accessibleFeatureState: AccessibleFeatureState = AccessibleFeatureState.ALL;

  constructor(private http: HttpClient) { }

  public checkPromoCode(promoCode: string): Observable<JsonResponse<PromoCodeLookup>> {
    return this.http
      .get<JsonResponse<PromoCodeLookup>>(`${this.userSubscriptionURL}promo/?code=${promoCode}`)
      .pipe(
        catchError((err) => {
          console.error(`SubscriptionsService, PROMO_CODE_CHECK: ${promoCode}`)
          throw of(null)
        })
      )
  }

  public handleSubscriptionPayment(planName: SubscriptionPlans,
                                   isPeriodMonthly: boolean,
                                   promoCode: string | null): Observable<JsonResponse<SubscriptionPaymentUrl>> {
    const url = `${this.userSubscriptionURL}?plan=${planName}&monthly=${isPeriodMonthly}&promoCode=${promoCode}`;
    return this.http
      .post<JsonResponse<SubscriptionPaymentUrl>>(url, null)
      .pipe(
        catchError((err) => {
          console.error(`SubscriptionsService, SUBSCRIPTION_PAYMENT_REQUEST: ${planName}`)
          throw of(null)
        })
      )
  }

  public updateSubscriptionAutoRenewal(isAutoRenewable: boolean): Observable<JsonResponse<any>> {
    return this.http.post<JsonResponse<any>>(`${this.userSubscriptionURL}${isAutoRenewable ? 'pause' : 'resume'}`, {})
  }

  // public setAccessibleFeaturesId(): void {
  //   this.mapHttp.getAccessibleFeatures()
  //     .subscribe(data => {
  //       if (data.object.h3r5Cells && data.object.h3r7Cells) {
  //         this.accessibleFeaturesList = [...data.object.h3r5Cells, ...data.object.h3r7Cells];
  //         this.accessibleFeatureState = AccessibleFeatureState.LIMITED;
  //       } else {
  //         this.accessibleFeatureState = AccessibleFeatureState.ALL;
  //       }
  //     });
  // }

  // public getAccessibleFeatures(): { state: AccessibleFeatureState, features: NaturalId[] } {
  //   return {
  //     state: this.accessibleFeatureState,
  //     features: this.accessibleFeaturesList,
  //   };
  // }

  public setAccessibleFeaturesAll(): void {
    this.accessibleFeatureState = AccessibleFeatureState.ALL
  }

  // public getIsAllFeaturesAvailable(): boolean {
  //   return this.getAccessibleFeatures().state === AccessibleFeatureState.ALL
  // }

  // public getIsFeatureAccessible(id: NaturalId): boolean {
  //   return this.getAccessibleFeatures().features.includes(id)
  // }
}
