import { Pipe, PipeTransform } from '@angular/core';
import {
  AIR_POLLUTION,
  BACHELORS_DEGREE_OR_HIGHER_FRACTION,
  cdcBulkFeatures,
  chrBulkFeatures,
  crimeRatesList,
  GENDER_PAY_GAP,
  HOUSEHOLDS,
  hwwFeatures,
  LIFE_EXPECTANCY,
  MEDIAN_TENURE,
  PEOPLE_PER_MI2,
  POPULATION,
  ratioLayers,
  SPECIAL_HEALTH_CARE_NEEDS_INDEX,
  STRUCTURE_BUILT_MEDIAN_YEAR,
  targetAudienceIndustries,
  UNHEALTHY_BEHAVIOUR_INDEX,
} from '../../../../../../shared/types/feature-data-type';
import { formatPercent } from '../../../../../../shared/pipes/number-formatter.pipe';

@Pipe({
  name: 'formatFilterInputValue',
})
export class FormatFilterInputValuePipe implements PipeTransform {
  transform(value: number, feature: string): unknown {
    return formatFilterValues(value, feature);
  }
}

function formatFilterValues(value: number | string, feature: string): string {
  let numValue: number;

  if (typeof value === 'string') {
    numValue = parseFloat(value);
  } else {
    numValue = value;
  }

  if (Number.isInteger(numValue) ||
    feature === POPULATION ||
    feature === HOUSEHOLDS ||
    feature === PEOPLE_PER_MI2 ||
    feature === LIFE_EXPECTANCY ||
    feature === STRUCTURE_BUILT_MEDIAN_YEAR ||
    feature === MEDIAN_TENURE ||
    crimeRatesList.includes(feature)) {
    return numValue.toFixed(0)
  }

  if (feature === GENDER_PAY_GAP) {
    return `${(numValue * 100).toFixed(1)}`
  }

  if (targetAudienceIndustries.includes(feature)) {
    return `${(numValue * 100).toFixed(0)}`
  }

  if (hwwFeatures.includes(feature)) {
    return numValue.toFixed(2)
  }

  if (ratioLayers.includes(feature) ||
    cdcBulkFeatures.includes(feature) ||
    feature === UNHEALTHY_BEHAVIOUR_INDEX ||
    feature === SPECIAL_HEALTH_CARE_NEEDS_INDEX ||
    feature === BACHELORS_DEGREE_OR_HIGHER_FRACTION
  ) {
    return formatPercent(100 * numValue)
  }

  if (chrBulkFeatures.includes(feature)) {
    return feature === AIR_POLLUTION ? numValue.toFixed(1) : numValue.toFixed(0);
  }

  return numValue.toFixed(2)
}
