import { Pipe, PipeTransform } from '@angular/core';
import { hwwFeatures } from '../../../../shared/types/feature-data-type';

@Pipe({
  name: 'isCategoricalColorscale',
})
export class IsCategoricalColorscalePipe implements PipeTransform {
  transform(layer: string): boolean {
    // Expand if there will be more features
    const categoricalFeatures = [...hwwFeatures];

    return categoricalFeatures.includes(layer);
  }
}
