<div class="subscriptions-container">
  <div class="promo-code-container">
    Promo code
    <div class="input-wrapper">
      <input
        type="text"
        placeholder="Enter promo code"
        (input)="handlePromoCodeInput($event)"
        [value]="promoCode || ''"
        [ngClass]="(promoCodeDetails && promoCodeDetails.active) ? 'promo-active' : (promoCodeDetails && !promoCodeDetails.active) ? 'promo-invalid' : ''"
      />
      <button
        *ngIf="promoCode"
        class="clear-button"
        (click)="clearPromoCode()"
        aria-label="Clear promo code"
      >
        <i class="material-icons" style="font-size: 16px;">close</i>
      </button>
    </div>
  </div>

  <div class="toggle-wrapper">
    Monthly
    <mat-slide-toggle [(ngModel)]="!isPeriodMonthly" (change)="isPeriodMonthly = !isPeriodMonthly"></mat-slide-toggle>
    Yearly
  </div>

  <div class="info-container">
    <div class="plans-container">
      <app-plan-with-description
        *ngFor="let plan of plans; let i = index"
        [planInfo]="plan"
        #planElement
        [isPeriodMonthly]="isPeriodMonthly"
        [promoCodeDetails]="promoCodeDetails"
        [promoCode]="(promoCodeInputSubject | async)!"
        [isThisSubscriptionActive]="plan.requestKey == userSubscription"
        [isSubscriptionUrlSelected] = "i === urlSelectedPlanIndex"
        (toggleOuterButton)="onToggleOuterButton(i)"
      ></app-plan-with-description>
    </div>
  </div>
</div>
